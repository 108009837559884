// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import Aisle from './app/Aisle';
import reportWebVitals from './reportWebVitals';
import { StrictMode } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { green, brown } from '@mui/material/colors';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const theme = createTheme({
  palette: {
    primary: {
      main: brown[400],
    },
    secondary: {
      main: green[500],
    },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://lietfab.com">
        LIETFAB
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Aisle />

      <Copyright sx={{ mt: 5 }} />
    </ThemeProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
