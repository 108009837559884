    import Box from '@mui/material/Box';
    import ImageList from '@mui/material/ImageList';
    import ImageListItem from '@mui/material/ImageListItem';
    import ImageListItemBar from '@mui/material/ImageListItemBar';
    
    export default function Catalogue() {
      const openInNewTab = (url: string) => {
        window.open(url, "_blank", "noreferrer");
      };
      return (
        <Box data-testid="catalogue">
          <ImageList variant="masonry" cols={3} gap={8}>
            {itemData.map((item) => (
              <ImageListItem className="catalogue-item" key={item.img}>
                <img
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                  onClick={()=>openInNewTab(item.link)}
                />
                <ImageListItemBar position="bottom" title={item.price} />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      );
    }
    
    const itemData = [
      {
        img: 'images/LF-B1-RNTS/1.jpg',
        title: 'Men\'s 100% Cotton Regular Fit T-Shirt | Solid | Short Sleeve | Ocean Blue',
        price: 'RS 349(INR)',
        link: "https://www.amazon.in/dp/B0D7TTK57G"
      },
      {
        img: 'images/LF-BB-VNTS/1.jpg',
        title: 'Men\'s 100% Cotton Regular Fit T-Shirt | V Neck | Solid | Short Sleeve | Black',
        price: 'RS 349(INR)',
        link: "https://www.amazon.in/dp/B0D7TTCQKR"
      },
      {
        img: 'images/LF-G3-RNTS/1.jpg',
        title: 'Men\'s 100% Cotton Regular Fit T-Shirt | Solid | Short Sleeve | Olive Green',
        price: 'RS 349(INR)',
        link: "https://www.amazon.in/dp/B0D7WBTHHQ"
      },
      {
        img: 'images/LF-GB-RTS/1.jpg',
        title: 'Men\'s 100% Cotton Regular Fit T-Shirt | Ruglan | Solid | Full Sleeve | Grey and Black',
        price: 'RS 449(INR)',
        link: "https://www.amazon.in/dp/B0D7TS4WGR"
      },
      {
        img: 'images/LF-L1-RNTS/1.jpg',
        title: 'Men\'s 100% Cotton Regular Fit T-Shirt | Solid | Short Sleeve | Lilac',
        price: 'RS 349(INR)',
        link: "https://www.amazon.in/dp/B0D7SYB2TM"
      },
      {
        img: 'images/LF-W1-RNTS/1.jpg',
        title: 'Men\'s 100% Cotton Regular Fit T-Shirt | Solid | Short Sleeve | White',
        price: 'RS 399(INR)',
        link: "https://www.amazon.in/dp/B0D7TSFVZY"
      },
      {
        img: 'images/LF-M2-RNTS/1.jpg',
        title: 'Men\'s 100% Cotton Regular Fit T-Shirt | Solid | Short Sleeve | Mauve',
        price: 'RS 349(INR)',
        link: "https://www.amazon.in/dp/B0D7TT31FZ"
      },
      {
        img: 'images/LF-N1-RNTS/1.jpg',
        title: 'Men\'s 100% Cotton Regular Fit T-Shirt | Solid | Short Sleeve | Navy Heather',
        price: 'RS 349(INR)',
        link: "https://www.amazon.in/dp/B0D7TSQV7G"
      },
      {
        img: 'images/LF-M1-RNTS/1.jpg',
        title: 'Men\'s 100% Cotton Regular Fit T-Shirt | Solid | Short Sleeve | Maroon',
        price: 'RS 399(INR)',
        link: "https://www.amazon.in/dp/B0D7TV5FDK"
      }
    ];
    