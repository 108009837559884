import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import SendIcon from '@mui/icons-material/Send';


import HandshakeIcon from '@mui/icons-material/Handshake';
import UnstyledTextareaIntroduction from './CustomTextArea';
import { useEffect, useState } from 'react';
import { useMutationHook } from '../hooks/useMutationHook';


export default function ContactForm() {
  const {mutate, isSuccess} = useMutationHook();

const [msg, setMsg] =  useState("");

  const getDefaultState = () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      message: '',
      receiveEmails: 'YES',
    };
  }
  useEffect(()=>{
    if(isSuccess){
    setMsg("Submitted Successfully!");
    }

    setTimeout(()=>{
      setMsg("");

    }, 5000)
  }, [isSuccess])

  const [formState, setFormState] =  useState(getDefaultState());


  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const body = new FormData(event.currentTarget);
    mutate(body);
    setFormState(getDefaultState());
  };
  const handleChange = (e: any) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <HandshakeIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Contact Us
          </Typography>

          <Typography className='form-success-msg' component="h1" variant="h5">
            {msg}
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  value={formState.firstName}
                  onChange={handleChange}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  value={formState.lastName}
                  onChange={handleChange}
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={formState.email}
                  onChange={handleChange}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="mobile"
                  label="Phone number"
                  name="mobile"
                  value={formState.mobile}
                  onChange={handleChange}
                  autoComplete="tel"
                />
              </Grid>
              <Grid item xs={12}>
                  <UnstyledTextareaIntroduction
                  onChange={handleChange} value={formState.message} />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox name="receiveEmails" 
                  value={formState.receiveEmails} 
                  onChange={handleChange} color="primary" />}
                  label="I want to receive marketing & promotional emails."
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<SendIcon />}
            >
              Send
            </Button> 
          </Box>

        </Box>
      </Container>
  );
}