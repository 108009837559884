import './Aisle.css';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import HomePage from './pages/HomePage';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import ContactForm from './components/ContactForm';

function Aisle() {
  const queryClient = new QueryClient({defaultOptions: {
    queries: {
      retry: false,
    },
  },})

  return (
    <QueryClientProvider client={queryClient}>
      <ResponsiveAppBar/>
      <h2 className="aisle">
        Our Products
      </h2>
      <HomePage/>
      <ContactForm/>
    </QueryClientProvider>
  );
}

export default Aisle;
