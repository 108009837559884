import axios from 'axios';

const http = axios.create({
  baseURL: `https://script.google.com/macros/s`,
  timeout: 15000,
  // headers: {},
  maxRedirects: 20
});
// http.defaults.maxRedirects = 2; // Set to 0 to prevent automatic redirects

export default http;

