import http from "./base";

const get = async(postId: string)=>{
    const {data} = await http.get(`todos/${postId}`);
    return data;
}


const URL = 'https://script.google.com/macros/s/AKfycbzJlUnz307gU60SkyFUKcN56o4muwqN_nuvVXt8FKHWC58G59DXXsd-xV0mGDaRMt5M/exec'
const post = async(details: any)=>{

    const {data} = await http.post(
        URL, details 
    );
    return data;
}

 export {get, post};
